<template>
  <div class="page-wrapper white">
    <div class="page-info psychology-page">
      <div class="psychology-h1" v-if="student.student_name">{{ student.student_name }}的结案信息</div>
      <div class="person-info flex">
        <div class="flex-shrink img mr-6">
          <Photo :src="student.photo"></Photo>
        </div>
        <div class="flex-1 info">
          <div class="top">
            <span class="name">{{ student.student_name }}</span>
            <span class="sex female" v-if="student.student_gender_text === '女'"><i class="el-icon-female"></i></span>
            <span class="sex male" v-else><i class="el-icon-male"></i></span>
          </div>
          <el-row class="page-row no-margin">
            <el-col class="item" :sm="12" :md="8">
              <div class="inner">
                <div class="label">学号：</div>
                <div class="content">{{ student.student_no }}</div>
              </div>
            </el-col>
            <el-col class="item" :sm="12" :md="8">
              <div class="inner">
                <div class="label">行政班：</div>
                <div class="content">{{ student.class_name }}</div>
              </div>
            </el-col>
            <el-col class="item" :sm="12" :md="8">
              <div class="inner">
                <div class="label">年级：</div>
                <div class="content">{{ student.grade_name }}</div>
              </div>
            </el-col>
            <el-col class="item" :sm="12" :md="8">
              <div class="inner">
                <div class="label">校区：</div>
                <div class="content">{{ student.school_name }}</div>
              </div>
            </el-col>
            <el-col class="item" :sm="12" :md="8">
              <div class="inner">
                <div class="label">结案时间：</div>
                <div class="content">{{ student.send_feedback_time }}</div>
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
      <History :visible.sync="isShowHistory" :group_id="conclusion.group_id" :id="conclusion.id"></History>
      <Card title="结案信息">
        <div class="form-padding">
          <el-row class="page-row w180">
            <el-col class="item">
              <div class="inner">
                <div class="label">咨询老师</div>
                <div class="content">{{ conclusion.teacher_name }}</div>
              </div>
            </el-col>
            <el-col class="item">
              <div class="inner">
                <div class="label">咨询时间开始</div>
                <div class="content">{{ conclusion.start_time }}</div>
              </div>
            </el-col>
            <el-col class="item">
              <div class="inner">
                <div class="label">咨询结束时间</div>
                <div class="content">{{ conclusion.end_time }}</div>
              </div>
            </el-col>
            <el-col class="item">
              <div class="inner">
                <div class="label">咨询总次数</div>
                <div class="content">
                  <template v-if="conclusion.speed">
                    <a href="javascript:void(0)" @click="handleShowHistory">{{ conclusion.speed }} 次</a>
                  </template>
                  <span v-else>无</span>
                </div>
              </div>
            </el-col>
            <el-col class="item">
              <div class="inner">
                <div class="label">咨询结束原因</div>
                <div class="content">{{ conclusion.summary_reason }}</div>
              </div>
            </el-col>
            <el-col class="item">
              <div class="inner">
                <div class="label">结束时的状态</div>
                <div class="content">
                  <div class="gray-box">{{ conclusion.student_status }}</div>
                </div>
              </div>
            </el-col>
            <el-col class="item">
              <div class="inner">
                <div class="label">咨询过程中的变化</div>
                <div class="content">
                  <div class="gray-box">{{ conclusion.student_change }}</div>
                </div>
              </div>
            </el-col>
            <el-col>
              <div class="inner">
                <div class="label">
                  <div class="form-item-section-title my-2">求助者的变化</div>
                </div>
              </div>
            </el-col>
            <el-col class="item">
              <div class="inner">
                <div class="label">1.求助者自己的评估</div>
                <div class="content">
                  <div class="gray-box">{{ conclusion.student_estimate || '无' }}</div>
                </div>
              </div>
            </el-col>
            <el-col class="item">
              <div class="inner">
                <div class="label">2.前后心理测验的比对</div>
                <div class="content">
                  <div class="gray-box">{{ conclusion.student_contrast || '无' }}</div>
                </div>
              </div>
            </el-col>
            <el-col class="item">
              <div class="inner">
                <div class="label">3.求助者周围人士的评估</div>
                <div class="content">
                  <div class="gray-box">{{ conclusion.interpersonal_estimate || '无' }}</div>
                </div>
              </div>
            </el-col>
            <el-col class="item">
              <div class="inner">
                <div class="label">4.社会适应的现实</div>
                <div class="content">
                  <div class="gray-box">{{ conclusion.social_adaptation || '无' }}</div>
                </div>
              </div>
            </el-col>
            <el-col class="item">
              <div class="inner">
                <div class="label">5.咨询师的评估</div>
                <div class="content">
                  <div class="gray-box">{{ conclusion.teacher_estimate || '无' }}</div>
                </div>
              </div>
            </el-col>
            <el-col class="item">
              <div class="inner">
                <div class="label">今后注意事项及建议</div>
                <div class="content">
                  <div class="gray-box">{{ conclusion.comment || '无' }}</div>
                </div>
              </div>
            </el-col>
          </el-row>
        </div>
      </Card>
      <Card title="学生反馈信息" v-if="!['{}', '[]'].includes(JSON.stringify(feedback))">
        <div class="form-padding">
          <el-row class="page-row w180">
            <el-col class="item">
              <div class="inner">
                <div class="label">心理老师的态度</div>
                <div class="content">{{ feedback.service_score || '无' }}</div>
              </div>
            </el-col>
            <el-col class="item">
              <div class="inner">
                <div class="label">心理辅导是否解决问题</div>
                <div class="content">{{ feedback.solve_score || '无' }}</div>
              </div>
            </el-col>
            <el-col class="item">
              <div class="inner">
                <div class="label">心理辅导的满意度</div>
                <div class="content">{{ feedback.whole_score || '无' }}</div>
              </div>
            </el-col>
            <el-col class="item">
              <div class="inner">
                <div class="label">再出现心理困扰，是否愿意来心理辅导室寻求帮助</div>
                <div class="content">{{ feedback.wish_score || '无' }}</div>
              </div>
            </el-col>
            <el-col class="item">
              <div class="inner">
                <div class="label">希望的求助方式</div>
                <div class="content">{{ feedback.wish_content || '无' }}</div>
              </div>
            </el-col>
            <el-col class="item">
              <div class="inner">
                <div class="label">对学校心理咨询的建议</div>
                <div class="content">
                  <div class="gray-box">{{ feedback.advise || '无' }}</div>
                </div>
              </div>
            </el-col>
            <el-col class="item">
              <div class="inner">
                <div class="label">本阶段心理咨询的收获</div>
                <div class="content">
                  <div class="gray-box">{{ feedback.experience || '无' }}</div>
                </div>
              </div>
            </el-col>
          </el-row>
        </div>
      </Card>
    </div>
    <div class="page-tool border">
      <el-button type="primary" @click="$router.back()">返回</el-button>
    </div>
  </div>
</template>
<script>
import History from '../components/History.vue'
import Photo from '../components/Photo.vue'
import Card from '@/components/common/Card.vue'
import { getApi2 } from '@/api'

export default {
	_config:{"route":{"path":"detail","meta":{"title":"详情"}}},
  components: { History, Card, Photo },
  data() {
    return {
      id: 0,
      isShowHistory: false,
      student: {},
      conclusion: {},
      feedback: {}
    }
  },
  created() {
    this.id = +this.$route.query.id
    this.getDetail()
  },
  methods: {
    getDetail() {
      getApi2('/evaluation/sum-up/done-details', { id: this.id }).then(res => {
        const { student, conclusion, feedback } = res
        if (student) {
          this.student = student
        }
        if (conclusion) {
          this.conclusion = conclusion
        }
        if (feedback) {
          this.feedback = feedback
        }
      })
    },
    handleShowHistory() {
      this.isShowHistory = true
    }
  }
}
</script>
